var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"self_sec"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"login_signup_form py_60 mt_10"},[_c('h2',{staticClass:"text_black font_size_36 mb_40"},[_vm._v("Sign Up")]),_c('form',{attrs:{"action":""}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"mt_68 d-flex justify-content-between align-items-center btn_link_box"},[_vm._m(4),_c('div',{staticClass:"accout_box"},[_c('p',{staticClass:"m-0"},[_vm._v(" Already have an accout? "),_c('router-link',{attrs:{"to":{ name: 'login_two' }}},[_c('span',[_vm._v("Login")])])],1)])])])])]),_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font_size_24 mb_20 font_bold"},[_vm._v("Name")]),_c('input',{staticClass:"mb_50 form-control",attrs:{"type":"text","placeholder":"Name"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font_size_24 mb_20 font_bold"},[_vm._v("Email")]),_c('input',{staticClass:"mb_50 form-control",attrs:{"type":"text","placeholder":"Email"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font_size_24 mb_20 font_bold"},[_vm._v("Password")]),_c('input',{staticClass:"mb_50 form-control",attrs:{"type":"text","placeholder":"Password"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font_size_24 mb_20 font_bold"},[_vm._v("Confirm Password")]),_c('input',{staticClass:"mb_50 form-control",attrs:{"type":"text","placeholder":"Confirm Password"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('a',{staticClass:"btn btn_primary",attrs:{"href":"#"}},[_vm._v("Sign up")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 p-0"},[_c('div',{staticClass:"self_img"},[_c('img',{attrs:{"src":require("../assets/images/schedule_img.png"),"alt":"img"}})])])
}]

export { render, staticRenderFns }