<template>
   <div>
      <section class="self_sec">
         <div class="container-fluid">
            <div class="row">
               <div class="col-md-6">
                  <div class="login_signup_form py_60 mt_10">
                     <h2 class="text_black font_size_36 mb_40">Sign Up</h2>
                     <form action="">
                        <div class="form-group">
                           <label class="font_size_24 mb_20 font_bold">Name</label>
                           <input type="text" placeholder="Name" class="mb_50 form-control">
                        </div>
                        <div class="form-group">
                           <label class="font_size_24 mb_20 font_bold">Email</label>
                           <input type="text" placeholder="Email" class="mb_50 form-control">
                        </div>
                        <div class="form-group">
                           <label class="font_size_24 mb_20 font_bold">Password</label>
                           <input type="text" placeholder="Password" class="mb_50 form-control">
                        </div>
                        <div class="form-group">
                           <label class="font_size_24 mb_20 font_bold">Confirm Password</label>
                           <input type="text" placeholder="Confirm Password" class="mb_50 form-control">
                        </div>
                        <div class="mt_68 d-flex justify-content-between align-items-center btn_link_box">
                           <div class="">
                              <a href="#" class="btn btn_primary">Sign up</a>
                           </div>
                           <div class="accout_box">
                              <p class="m-0">
                                 Already have an accout? 
                                 <router-link :to="{ name: 'login_two' }"><span>Login</span></router-link>
                              </p>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
               <div class="col-md-6 p-0">
                  <div class="self_img">
                     <img src="../assets/images/schedule_img.png" alt="img">
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>
<script>
   export default {
     name: "Signup",
   
   };
</script>